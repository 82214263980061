import React, { useContext, useEffect, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { LoadingButton } from '@one-thd/sui-atomic-components';
import {
  useLazyDataModel,
  params,
  shape as shapeType,
  customType,
  string as stringType,
} from '@thd-nucleus/data-sources';

import { HOME_DEPOT_HOSTNAME } from '../../constants/Constants';
import { ColorDetailsContext } from '../../context/Contexts';

const HIDDEN_LINK_ID = 'U1VQRVJfU0VDUkVUX0lE';

export const BuyButtons = ({
  containerClassName,
  buyPaintClassName,
  buySampleClassName,
  buyPaintAnalyticsCb,
  buySampleAnalyticsCb,
  colorDetailsDataProp,
  showFreeShippingMessage,
  buttonClassName
}) => {
  const [action, setAction] = useState(null);
  const { paintIdsMissingSamples, setPaintIdsMissingSamples } = useContext(ColorDetailsContext);
  const { colorBrand, colorNumber } = colorDetailsDataProp || {};
  const paintId = `${colorBrand}-${colorNumber}`;

  const [requestColorDetails, colorDetailsResponse] = useLazyDataModel('paintColorDetails', {
    variables: {
      channel: 'DESKTOP' // DON'T CHANGE - Details calls are always done on Desktop
    },
    ssr: false
  });

  useEffect(() => {
    setAction(null);
  }, [colorNumber]);

  useEffect(() => {
    if (action === 'buy-paint' && colorDetailsResponse.data?.paintColorDetails?.topSellers) {
      buyPaintAnalyticsCb();
      const redirectUrl = `${colorDetailsResponse.data.paintColorDetails.topSellers.canonicalURL}`;
      if (redirectUrl.length) {
        window.location.href = redirectUrl;
      }
    } else if (action === 'buy-sample') {
      if (colorDetailsResponse.data?.paintColorDetails?.sample?.itemId) {
        const sampleId = colorDetailsResponse.data.paintColorDetails.sample.itemId;
        const cartReqParams = {
          itemId: sampleId,
          quantity: 1,
        };
        const cartOptions = {
          channel: 'desktop',
          host: HOME_DEPOT_HOSTNAME,
          misship: false,
          paypal: false
        };

        window.LIFE_CYCLE_EVENT_BUS.trigger('cart.add-to-cart', {
          cartReqParams,
          cartOptions
        });

        buySampleAnalyticsCb(sampleId);
      } else if (!colorDetailsResponse.loading && !paintIdsMissingSamples.includes(paintId)) {
        setPaintIdsMissingSamples([
          ...paintIdsMissingSamples,
          paintId
        ]);
      }
    }
  }, [colorDetailsResponse]);

  const handleBuyPaintClick = () => {
    setAction('buy-paint');
    requestColorDetails({
      variables: {
        vendor: colorBrand,
        colornumber: colorNumber
      }
    });
  };

  const handleBuySampleClick = () => {
    setAction('buy-sample');
    requestColorDetails({
      variables: {
        vendor: colorBrand,
        colornumber: colorNumber
      }
    });
  };

  const hasNoSample = paintIdsMissingSamples.includes(paintId)
   || (!!action && !colorDetailsResponse.loading && !colorDetailsResponse.data?.paintColorDetails?.sample?.itemId);

  const getButtonProps = (type) => {
    const isSampleButton = type === 'sample';
    const buttonProps = {
      disabled: colorDetailsResponse.loading || (isSampleButton && hasNoSample),
      loading: action === `buy-${type}` && colorDetailsResponse.loading,
      onClick: !isSampleButton ? handleBuyPaintClick : handleBuySampleClick,
      variant: !isSampleButton ? 'secondary' : 'primary'
    };
    if (!isSampleButton && !!buyPaintClassName) {
      buttonProps.className = buyPaintClassName;
    } else if (isSampleButton && !!buySampleClassName) {
      buttonProps.className = buySampleClassName;
    }
    return buttonProps;
  };

  return (
    <>
      <div className={containerClassName}>
        {/* eslint-disable-next-line */}
        <a id={HIDDEN_LINK_ID} href="" target="_blank" />
        <div className="sui-flex sui-w-full sui-flex-col sui-mt-2 sui-gap-2">
          <div className="sui-grow sui-w-full sui-flex sui-justify-around">
            <div className={buttonClassName}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <LoadingButton {...getButtonProps('paint')}>
                <span data-bugbear-id="colorDetailsBuyPaint">
                  View Paint Details
                </span>
              </LoadingButton>
            </div>
          </div>
          <div className="sui-grow sui-w-full sui-flex sui-justify-around">
            <div className={buttonClassName}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <LoadingButton {...getButtonProps('sample')}>
                <span data-bugbear-id="colorDetailsBuySample">
                  {hasNoSample ? 'No Samples Available' : 'Buy 8 oz. Sample'}
                </span>
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
      {
        showFreeShippingMessage && (
          <div className="sui-flex sui-justify-center sui-text-success">
            <div className="u__bold">
              <span className="u__text--success">FREE</span>
            </div>
            <span className="sui-pl-1">delivery on all samples</span>
          </div>
        )
      }
    </>
  );
};

BuyButtons.propTypes = {
  buyPaintAnalyticsCb: func,
  buyPaintClassName: string,
  buySampleAnalyticsCb: func.isRequired,
  buySampleClassName: string,
  colorDetailsDataProp: shape({
    colorBrand: string,
    colorNumber: string
  }).isRequired,
  containerClassName: string.isRequired,
  showFreeShippingMessage: bool,
  buttonClassName: string
};

BuyButtons.defaultProps = {
  buyPaintAnalyticsCb: () => {},
  buyPaintClassName: '',
  buySampleClassName: '',
  showFreeShippingMessage: false,
  buttonClassName: ''
};

BuyButtons.dataModel = {
  paintColorDetails: params({
    colornumber: stringType().isRequired(),
    vendor: stringType().isRequired(),
    channel: customType('Channel').enum(['DESKTOP', 'MOBILE'], 'DESKTOP')
  }).shape({
    sample: shapeType({
      itemId: stringType()
    }),
    topSellers: shapeType({
      canonicalURL: stringType()
    })
  })
};