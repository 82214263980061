import {
  MOBILE,
  TABLET,
  DESKTOP,
  BREAKPOINTS,
  COLOR_FAMILIES,
  BLACK,
  WHITE
} from '../constants/Constants';

// eslint-disable-next-line max-len
export const isBrowser = new Function('try {return this === window;}catch(e){ return false;}'); // eslint-disable-line no-new-func

export const getBreakpoint = () => {
  if (isBrowser()) {
    const width = Math.min(window.innerWidth, window.outerWidth);
    let breakpoint;
    for (let i = 0; i < BREAKPOINTS.length; i += 1) {
      if (width >= BREAKPOINTS[i] && BREAKPOINTS[i + 1] && width < BREAKPOINTS[i + 1]) {
        breakpoint = BREAKPOINTS[i];
        break;
      } else if (width < BREAKPOINTS[i]) {
        breakpoint = BREAKPOINTS[i];
        break;
      } else if (width >= BREAKPOINTS[i] && !BREAKPOINTS[i + 1]) {
        breakpoint = BREAKPOINTS[i];
        break;
      }
    }
    return breakpoint;
  }
  return null;
};

export const getChannel = (breakpoint) => {
  if (isBrowser()) {
    if (breakpoint < 576) {
      return MOBILE;
    }
    if (breakpoint < 992) {
      return TABLET;
    }
  }
  return DESKTOP;
};

const isBackgroundColorLight = (rgb = '') => {
  const arrayOfRGBValues = rgb.split(':');
  const luminance = 1 - (0.299 * arrayOfRGBValues[0] + 0.587 * arrayOfRGBValues[1] + 0.114 * arrayOfRGBValues[2]) / 255;
  return (luminance < 0.5);
};

export const getContentColor = (rgb = '') => {
  return isBackgroundColorLight(rgb) ? BLACK : WHITE;
};

export const getBackgroundColorFromRgbVal = (rgb = '') => `rgb(${rgb.replace(/:/gi, ',')})`;

// eslint-disable-next-line max-len
export const getColorFamilyName = (family = '') => (COLOR_FAMILIES.filter(({ key }) => family.toLowerCase() === key)[0] || {}).name;

export const getActiveBrand = (dimensions = []) => {
  return (
    (
      dimensions.filter(({ label }) => label === 'Brand')[0] || {}
    ).refinements || []
  ).filter(({ selected }) => selected)[0] || {};
};

export const getActiveFilter = (dimensions = []) => (
  getActiveBrand(dimensions).label ? (getActiveBrand(dimensions).label)?.toUpperCase() : 'All Brands'
);

// TODO: put into separate utils file for seo stuff etc
const getTitle = ({ colorFamily, dimensions = [] }) => {
  const brand = getActiveBrand(dimensions).label;

  return `${getColorFamilyName(colorFamily)} Paint Colors ${brand ? `from ${brand} ` : ''}- The Home Depot`;
};

export const updateTitle = (data) => {
  const title = getTitle(data);
  if (isBrowser() && typeof window !== 'undefined' && window.document && title !== window.document.title) {
    window.document.title = title;
  }
};

export const updateColorDetailsData = (colorDetailsData, chosenColorNumber) => {
  let resultRgb;
  let resultColorNumber;
  let resultVendor;
  let resultColorName;

  const updatedColorsArray = (colorsArrayToMap = []) => colorsArrayToMap.map((color) => {
    if (color.colorNumber === chosenColorNumber) {
      resultVendor = color.vendor;
      resultColorName = color.colorName;
      resultColorNumber = color.colorNumber;
      resultRgb = color.rgb;
    }
    return ({
      rgb: color.rgb,
      colorNumber: color.colorNumber,
      vendor: color.vendor,
      colorName: color.colorName,
      selected: color.colorNumber === chosenColorNumber,
    });
  });

  const updatedSimilarColors = updatedColorsArray(colorDetailsData?.similarColors);
  const updatedCoordinatingColors = updatedColorsArray(colorDetailsData?.coordinatingColors);

  return {
    colorNumber: resultColorNumber,
    similarColors: updatedSimilarColors,
    coordinatingColors: updatedCoordinatingColors,
    vendor: resultVendor,
    colorName: resultColorName,
    rgb: resultRgb,
  };
};

export const isObjectEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

export const logError = (error) => {
  if (window.isDebugMode) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};