import {
  extend,
  params,
  shape,
  customType,
  arrayOf as arrayOfType,
  bool as boolType,
  string as stringType,
  number as numberType
} from '@thd-nucleus/data-sources';
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';

const colorModel = shape({
  colorName: stringType(),
  colorNumber: stringType(),
  isAnchor: boolType(),
  rgb: stringType(),
  vendor: stringType(),
});

export const dataModel = extend({
  product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    dataSource: stringType(),
    identifiers: shape({
      productLabel: stringType()
    })
  }),
  paint: params({
    colorfamily: stringType(),
    navbarindex: numberType(),
    channel: customType('Channel').enum(['DESKTOP', 'MOBILE'], 'DESKTOP'),
    vendor: stringType()
  }).shape({
    breadCrumbs: arrayOfType(shape({
      label: stringType(),
      dimensionName: stringType(),
      url: stringType()
    })),
    colors: arrayOfType(shape({
      colorNumber: stringType(),
      vendor: stringType(),
      colorName: stringType(),
      rgb: stringType()
    })),
    dimensions: arrayOfType(shape({
      label: stringType(),
      refinements: arrayOfType(shape({
        label: stringType(),
        refinementKey: stringType(),
        selected: boolType()
      }))
    })),
    navBars: arrayOfType(shape({
      navBarIndex: stringType(),
      colorName: stringType(),
      rgb: stringType(),
      selected: boolType()
    })),
    searchReport: shape({
      numberOfColumns: stringType(),
      totalColors: numberType(),
      totalNavBarColors: numberType(),
      colorFamily: stringType()
    })
  }),
  paintColorDetails: params({
    colornumber: stringType().isRequired(),
    vendor: stringType().isRequired(),
    channel: customType('Channel').enum(['DESKTOP', 'MOBILE'], 'DESKTOP')
  }).shape({
    color: colorModel,
    coordinatingColors: arrayOfType(colorModel),
    sample: shape({
      itemId: stringType(),
      canonicalURL: stringType()
    }),
    similarColors: arrayOfType(colorModel),
    topSellers: shape({
      itemId: stringType(),
      canonicalURL: stringType()
    })
  })
},
Breadcrumbs
);